import './App.css';

function App() {
  return (
    <div className="parent">
      <div id="divimg">
        {/*<img
          class="child"
          alt=""
          src={URL}/>*/}
      </div>
      <a href="https://mangorow.vivaholidays.com.au/Mango_Viva_ROW/#/login"><div class="link1"></div></a>
      <a href="https://mangopac.vivaholidays.com.au/Mango_Viva_Pacific/#/login"><div class="link2"></div></a>
    </div>
  );
}

export default App;
